var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"cms-create-site","title":"New Site","no-close-on-backdrop":""},on:{"ok":_vm.onOk}},[_c('ValidationObserver',{ref:"cmsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"site_name","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Site name"}},[_c('b-form-input',{attrs:{"id":"site_name","state":errors[0] ? false : null},model:{value:(_vm.createSiteForm.siteName),callback:function ($$v) {_vm.$set(_vm.createSiteForm, "siteName", $$v)},expression:"createSiteForm.siteName"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"site_name","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Agent","description":"If agent does not found on the list this might indicate that agent is already has cms site."}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.createSiteForm.agentId),callback:function ($$v) {_vm.$set(_vm.createSiteForm, "agentId", $$v)},expression:"createSiteForm.agentId"}},[_c('b-form-select-option',{attrs:{"disabled":"","value":""}},[_vm._v("No agent was found.")]),_vm._l((_vm.agents),function(item,index){return _c('b-form-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2)],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }