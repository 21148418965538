<template>
  <b-modal
    id="cms-create-site"
    title="New Site"
    no-close-on-backdrop
    @ok="onOk"
  >
    <ValidationObserver ref="cmsForm" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          v-slot="{ errors }"
          name="site_name"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Site name">
            <b-form-input
              id="site_name"
              v-model="createSiteForm.siteName"
              :state="errors[0] ? false : null"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="site_name"
          :rules="{
            required: true,
          }"
        >
          <b-form-group
            label="Agent"
            description="If agent does not found on the list this might indicate that agent is already has cms site."
          >
            <b-form-select
              v-model="createSiteForm.agentId"
              :state="errors[0] ? false : null"
            >
              <b-form-select-option disabled value=""
                >No agent was found.</b-form-select-option
              >
              <b-form-select-option
                v-for="(item, index) of agents"
                :key="index"
                :value="item.id"
                >{{ item.name }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </ValidationProvider>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      createSiteForm: {
        agentId: null,
        siteName: '',
      },
    }
  },
  computed: {
    ...mapState({
      isCreatingCmsSite: (state) => state.cms.isCreatingCmsSite,
      cmsSites: (state) => state.cms.cmsSites,
    }),
    ...mapGetters(['siteId', 'userInfo']),
    createdSiteAgentIds() {
      return this.cmsSites.map((site) => site.agentId)
    },
    /**
     * only one who does not have yet create cms site.
     */
    agents() {
      const agents = []
      for (const master of this.userInfo.masters) {
        agents.push(...master.children)
      }
      return agents.filter(
        (agent) => !this.createdSiteAgentIds.includes(agent.id)
      )
    },
  },
  methods: {
    ...mapActions(['createCmsSite']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-create-referral'))
      }
    },
    async onSubmit(cb) {
      await this.createCmsSite(this.createSiteForm)

      cb()
    },
  },
}
</script>
